"use client";

import { usePathname } from 'next/navigation';
import LaunchBanner from '@/components/LaunchBanner';
import MaintenanceBanner from '@/components/MaintenanceBanner';

export default function RootLayoutWrapper({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const isDashboard = pathname === '/loading' ||
                     pathname.startsWith('/highlights') ||
                     pathname.startsWith('/editor');

  return (
    <>
      {!isDashboard && (
        <>
          {/* <LaunchBanner /> */}
          {/* <MaintenanceBanner />  */}
        </>
      )}
      {children}
    </>
  );
} 